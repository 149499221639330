import React from "react"
import Layout from "../components/Layout"
import AllBooks from "../components/AllBooks"
import Cercador from "../components/Cercador"
import Novetats from "../components/Novetats"
import CustomSlider from "../components/CustomSlider"
import useSlider from "../hooks/useSlider"
import Noticies from "../components/Noticies"
import SEO from "../components/seo"
const IndexPage = () => {
  const slider = useSlider()
  return (
    <Layout>
      <SEO
        title={`Index`}
        lang="ca"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="w-full bg-white h-64 sm:h-96 md:h-128 lg:h-128 xl:h-128">
        <CustomSlider {...slider} />
      </div>
      <Novetats />
      <Noticies />
    </Layout>
  )
}

export default IndexPage
