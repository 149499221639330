import React from "react"
//import Book from "./Book"
import useBooks from "../hooks/useBooks"
import Book from "./Book"

const Novetats = () => {
  const novetats = useBooks().filter(b => b.novetat)

  return (
    <React.Fragment>
      <div className="flex flex-wrap mt-6">
        <div className="w-full">
          <p className="font-title text-gray-900 text-xl border-b border-black mt-10 h-10">
            Novetats
          </p>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mt-12 -my-4">
        {novetats
          .sort((a, b) => a.num - b.num)
          .reverse()
          .map(book => {
            return (
              <div
                key={book.title}
                className={`w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-2 py-4 cursor-pointer`}
              >
                <Book {...book} />
              </div>
            )
          })}
      </div>
    </React.Fragment>
  )
}

export default Novetats
