import { graphql, useStaticQuery } from "gatsby"

const useSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            fields {
              sourceName
            }
            frontmatter {
              title
              imatge {
                sharp: childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMarkdownRemark.edges
    .filter(({ node }) => node.fields.sourceName === "slides")
    .map(({ node }) => ({
      title: node.frontmatter.title,
      imatge: node.frontmatter.imatge,
    }))
}

export default useSlider
