import React from "react"
import useNoticies from "../hooks/useNoticies"
import Noticia from "./Noticia"

const Noticies = () => {
  const noticies = useNoticies()

  return (
    <>
      <div className="flex flex-wrap mt-6">
        <div className="w-full">
          <p className="font-title text-gray-900 text-xl border-b border-black mt-10 h-10">
            Últimes notícies
          </p>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2 mt-12">
        {noticies?.splice(0, 4)?.map(noticia => {
          return (
            <div
              key={noticia.title}
              className={`w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-2 cursor-pointer`}
            >
              <Noticia {...noticia} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Noticies
