import React, { useState } from "react"
import Image from "gatsby-image"
import Slider from "@farbenmeer/react-spring-slider"

const CustomSlider = props => {
  const ArrowComponent = ({ onClick, direction }) => {
    return (
      <div
        style={{
          border: "solid #f0f0f0",
          borderWidth: "0px 2px 2px 0px",
          display: "inline-block",
          padding: 3,
          height: 22,
          width: 22,
          marginLeft: 8,
          marginRight: 8,
          transform: direction === "left" ? "rotate(135deg)" : "rotate(-45deg)",
        }}
        onClick={onClick}
      ></div>
    )
  }

  return (
    <Slider auto={3000}>
      {Object.values(props).map(({ imatge, title }) => (
        <Image
          key={title}
          fluid={imatge.sharp.fluid}
          alt={title}
          className="h-full"
          backgroundColor={"#7bbe7b"}
        />
      ))}
    </Slider>
  )
}

export default CustomSlider
